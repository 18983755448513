import { Controller, useFormContext } from "react-hook-form";

import SkillTypeSelect from "./SkillTypeSelect";

const SkillTypeField = () => {
  const {
    formState: { errors },
    control,
  } = useFormContext();

  return (
    <>
      <label
        htmlFor="skill-type-id"
        className="block text-sm font-medium leading-5 text-gray-900"
      >
        Kompetenztyp
      </label>
      <Controller
        control={control}
        name="skillTypeId"
        render={({ field }) => <SkillTypeSelect {...field} />}
        rules={{ required: false }}
      />
      {errors.skillTypeId && (
        <span className="text-sm font-light text-red-500">
          Kompetenztyp fehlt
        </span>
      )}
    </>
  );
};

export default SkillTypeField;
