import { useReactiveVar } from "@apollo/client";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { ChangeEvent, Fragment } from "react";
import { useIntl } from "react-intl";

import { useMutation } from "@apollo/client";

import { EMPLOYEE_CREATE_ONE, EMPLOYEE_MANY } from "../../schema/employee";

import { Link, PermissionRequired } from "components/ui/permissions";
import { currencyFormater } from "../../config/i18n";
import { Competence, Employee } from "../../types";
import MicroChart from "../charts/MicroChart";
import Avatar from "./Avatar";

import { selectedEmployeesVar } from "../../cache";

interface Props {
  employee: Employee;
}

const Item = ({ employee }: Props) => {
  const [createEmployee] = useMutation(EMPLOYEE_CREATE_ONE, {
    refetchQueries: [{ query: EMPLOYEE_MANY }],
  });

  const { formatMessage: f } = useIntl();
  const selectedEmployees = useReactiveVar(selectedEmployeesVar);
  const formattedSalary = currencyFormater.format(employee.salary);

  const onCheck = (event: ChangeEvent<HTMLInputElement>) => {
    const newIds = selectedEmployees?.includes(employee._id)
      ? selectedEmployees?.filter((id) => id !== employee._id)
      : [...(selectedEmployees ?? []), employee._id];
    selectedEmployeesVar(newIds);
  };

  const onCopy = () => {
    const competences = employee.competences.map((c: Competence) => ({
      skillId: c.skillId,
      value: c.value,
    }));

    const lastName = `${employee.lastName} Kopie`;

    createEmployee({
      variables: { ...employee, competences, lastName },
    });
  };

  return (
    <tr>
      <td className="px-6 py-4">
        <input
          type="checkbox"
          className="cursor-pointer relative w-5 h-5 border rounded border-gray-400 dark:border-gray-200 bg-white dark:bg-gray-800 outline-none"
          checked={selectedEmployees.includes(employee._id)}
          onChange={onCheck}
        />
      </td>
      <td className="px-6 py-4">
        <Link
          to={`/employees/${employee._id}/details`}
          className="w-12 h-12 bg-cover bg-center rounded-md -ml-2"
        >
          <Avatar
            key={employee._id}
            employee={employee}
            className="w-10 h-10"
          />
        </Link>
      </td>
      <td className="px-6 py-4">
        <div className="flex items-center">
          <Link to={`/employees/${employee._id}/details`}>
            <h3 className="text-gray-900 text-sm leading-5 font-medium truncate">
              {employee.firstName} {employee.lastName}
            </h3>
          </Link>
        </div>
      </td>
      <PermissionRequired module="employees.competency_profile">
        <td className="px-6 py-4">
          <Link to={`/employees/${employee._id}/profile`}>
            <div className="h-6 w-12">
              <MicroChart data={employee.competences} />
            </div>
          </Link>
        </td>
      </PermissionRequired>
      <PermissionRequired module="employees.workplace">
        <td className="px-6 py-4 space-x-2.5">
          <Link
            to={`/jobs/${employee.jobId}/details`}
            className="text-gray-900 text-sm leading-5 font-medium hover:text-gray-600 transition ease-in-out duration-150"
          >
            {employee.job?.name}
          </Link>

          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800">
            {employee.jobIds?.length}
          </span>
        </td>
      </PermissionRequired>
      <PermissionRequired module="employees.salary">
        <td className="px-6 py-4 whitespace-nowrap">
          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
            {formattedSalary}
          </span>
        </td>
      </PermissionRequired>
      <td className="px-6 py-4">
        <Menu as="div" className="relative inline-block text-left">
          {({ open }) => (
            <>
              <div>
                <Menu.Button className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-black rounded-md bg-opacity-20 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                  {f({ id: "actions.name" })}
                  <ChevronDownIcon
                    className="w-5 h-5 ml-2 -mr-1 text-violet-200 hover:text-violet-100"
                    aria-hidden="true"
                  />
                </Menu.Button>
              </div>
              <Transition
                show={open}
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  static
                  className="z-10 absolute right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                  <div className="px-1 py-1 ">
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          to={`/employees/${employee._id}/details`}
                          module="employees.details"
                          className={`${
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700"
                          } flex justify-between w-full px-4 py-2 text-sm leading-5 text-left`}
                        >
                          {f({ id: "actions.details" })}
                        </Link>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          to={`/employees/${employee._id}/profile`}
                          module="employees.competency_profile"
                          className={`${
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700"
                          } flex justify-between w-full px-4 py-2 text-sm leading-5 text-left`}
                        >
                          {f({ id: "profile.name" })}
                        </Link>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <span
                          className={`${
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700"
                          } flex justify-between w-full px-4 py-2 text-sm leading-5 text-left cursor-pointer`}
                          onClick={onCopy}
                        >
                          {f({ id: "actions.copy" })}
                        </span>
                      )}
                    </Menu.Item>
                  </div>
                  <div className="px-1 py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          to={{
                            pathname: `/employees/${employee._id}/match`,
                            state: { competences: employee.competences },
                          }}
                          className={`${
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700"
                          } flex justify-between w-full px-4 py-2 text-sm leading-5 text-left`}
                          module="employees.match"
                        >
                          {f({ id: "employees.match" })}
                        </Link>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          to={{
                            pathname: `/employees/${employee._id}/review`,
                            state: { competences: employee.competences },
                          }}
                          className={`${
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700"
                          } flex justify-between w-full px-4 py-2 text-sm leading-5 text-left`}
                          module="employees.review"
                        >
                          {f({ id: "reviews.name" })}
                        </Link>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          to={`/employees/${employee._id}/training`}
                          className={`${
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700"
                          } flex justify-between w-full px-4 py-2 text-sm leading-5 text-left`}
                          module="employees.further_education"
                        >
                          {f({ id: "employees.training" })}
                        </Link>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </>
          )}
        </Menu>
      </td>
    </tr>
  );
};

export default Item;
