import { gql } from "@apollo/client";

export const FILTER_EMPLOYEE_MANY = gql`
  query($skillIds: [ID], $unitId: ID) {
    employeeMany(
      filter: { skillIds: $skillIds, unitId: $unitId }
      limit: 100
      sort: NAME_ASC
    ) {
      firstName
      lastName
      gender
      email
      photo
      age
      seniority
      retireDate
      terminationDate
      job {
        name
      }
      jobIds
      salary
      competences {
        skill {
          name
        }
        skillId
        value
      }
      _id
    }
  }
`;

export const EMPLOYEE_MANY = gql`
  query($isApplicant: Boolean) {
    employeeMany(filter: { isApplicant: $isApplicant }, sort: NAME_ASC) {
      firstName
      lastName
      gender
      email
      photo
      hireDate
      applicantId
      jobId
      jobIds
      salary
      humatics {
        competenceValues
        h
        t
        ef
        mu
      }
      competences {
        skill {
          name
        }
        skillId
        value
      }
      unitId
      unit {
        name
      }
      revenues {
        year
        target
        actual
      }
      _id
    }
  }
`;

export const EMPLOYEE_MANY_GROUP_BY_UNIT = gql`
  query($skillIds: [ID], $employmentStatus: EmploymentStatus) {
    employeeManyGroupByUnit(
      filter: { skillIds: $skillIds, employmentStatus: $employmentStatus }
    ) {
      name
      count
      employees {
        firstName
        lastName
        gender
        email
        photo
        hireDate
        applicantId
        jobId
        job {
          _id
          name
          description
        }
        jobIds
        salary
        humatics {
          competenceValues
          h
          t
          ef
          mu
        }
        competences {
          skill {
            name
          }
          skillId
          value
        }
        unitId
        unit {
          name
        }
        revenues {
          year
          target
          actual
        }
        _id
      }
    }
  }
`;

export const EMPLOYEE_BY_ID = gql`
  query($_id: ID!) {
    employeeById(_id: $_id) {
      firstName
      lastName
      gender
      address {
        street
        zip
        city
        countryId
      }
      email
      photo
      birthDate
      hireDate
      retireDate
      terminationDate
      personnalNumber
      applicantId
      jobId
      job {
        _id
        name
        competences {
          skillId
          skill {
            name
          }
          value
        }
        humatics {
          h
          t
          ef
          mu
        }
        targetRevenue
      }
      jobIds
      jobs {
        _id
        name
      }
      salary
      humatics {
        competenceValues
        h
        h2
        t
        t2
        ef
        mu
        mu2
      }
      competences {
        skillId
        skill {
          name
        }
        value
      }
      diff {
        skillId
        value
      }
      competenceCoverage
      unitId
      unit {
        _id
        humatics {
          h
          t
          ef
          mu
        }
      }
      managerId
      employmentType
      employmentStatus
      revenues {
        year
        target
        actual
      }
      actualRevenue
      annualizedRevenue
      targetRevenue
      knowledgeValue
      fileIds
      files {
        name
        url
        _id
      }
      reviews {
        name
        description
        humatics {
          h
          t
          ef
          mu
        }
        competenceCoverage
        createdAt
        _id
      }
      reviewCompetences {
        skill {
          _id
          name
        }
        values {
          value
          name
        }
      }
      _id
    }
  }
`;

export const EMPLOYEE_CREATE_ONE = gql`
  mutation(
    $firstName: String!
    $lastName: String!
    $gender: Gender
    $email: String!
    $address: AddressInput
    $photo: String
    $birthDate: DateTime
    $hireDate: DateTime
    $retireDate: DateTime
    $terminationDate: DateTime
    $personnalNumber: String
    $applicantId: ID
    $jobId: ID
    $salary: Float!
    $competences: [CompetenceInput]
    $unitId: ID
    $managerId: ID
    $employmentType: EmploymentType
    $employmentStatus: EmploymentStatus
  ) {
    employeeCreateOne(
      input: {
        firstName: $firstName
        lastName: $lastName
        gender: $gender
        email: $email
        address: $address
        photo: $photo
        birthDate: $birthDate
        hireDate: $hireDate
        retireDate: $retireDate
        terminationDate: $terminationDate
        personnalNumber: $personnalNumber
        applicantId: $applicantId
        jobId: $jobId
        salary: $salary
        competences: $competences
        unitId: $unitId
        managerId: $managerId
        employmentType: $employmentType
        employmentStatus: $employmentStatus
      }
    ) {
      _id
      firstName
    }
  }
`;

export const EMPLOYEE_UPDATE_BY_ID = gql`
  mutation(
    $_id: ID!
    $firstName: String
    $lastName: String
    $gender: Gender
    $email: String
    $address: AddressInput
    $photo: String
    $birthDate: DateTime
    $hireDate: DateTime
    $retireDate: DateTime
    $terminationDate: DateTime
    $personnalNumber: String
    $jobId: ID
    $jobIds: [ID]
    $salary: Float
    $competences: [CompetenceInput]
    $unitId: ID
    $managerId: ID
    $employmentType: EmploymentType
    $employmentStatus: EmploymentStatus
    $revenues: [RevenueInput]
    $fileIds: [ID]
  ) {
    employeeUpdateById(
      input: {
        firstName: $firstName
        lastName: $lastName
        gender: $gender
        email: $email
        address: $address
        photo: $photo
        birthDate: $birthDate
        hireDate: $hireDate
        retireDate: $retireDate
        terminationDate: $terminationDate
        personnalNumber: $personnalNumber
        jobId: $jobId
        jobIds: $jobIds
        salary: $salary
        competences: $competences
        unitId: $unitId
        managerId: $managerId
        employmentType: $employmentType
        employmentStatus: $employmentStatus
        revenues: $revenues
        fileIds: $fileIds
      }
      _id: $_id
    ) {
      _id
      firstName
      lastName
      competences {
        skillId
        skill {
          name
        }
        value
      }
    }
  }
`;

export const EMPLOYEE_REMOVE_BY_ID = gql`
  mutation($_id: ID!) {
    employeeRemoveById(_id: $_id) {
      _id
      firstName
    }
  }
`;

export const EMPLOYEE_COUNT = gql`
  query {
    employeeCount
  }
`;

export const ACTIVE_EMPLOYEE_COUNT = gql`
  query {
    activeEmployeeCount
  }
`;

export const EMPLOYEE_COMPETENCES = gql`
  query {
    employeeCompetences {
      _id
      name
      value
      color
    }
  }
`;

export const EMPLOYEE_HUMATICS = gql`
  query {
    employeeHumatics {
      employeesH
      m
      h
      t
      r
      phi
    }
  }
`;

export const EMPLOYEE_DEMOGRAPHICS = gql`
  query {
    employeeDemographics {
      date
      employee {
        firstName
        lastName
        photo
        job {
          name
        }
        competences {
          skillId
          value
        }
      }
      values {
        m
        t
      }
    }
  }
`;

export const EMPLOYEE_BY_SALARY = gql`
  query {
    employeeBySalary(boundaries: [1000, 2000, 4000, 8000]) {
      _id
      count
    }
  }
`;

export const EMPLOYEE_BY_SCALE = gql`
  query($boundaries: [Int]) {
    employeeByScale(boundaries: $boundaries) {
      _id
      count
      employeeIds
      employees {
        salary
      }
      skillIds
    }
  }
`;

export const EMPLOYEE_TOTAL_SALARY = gql`
  query {
    employeeTotalSalary
  }
`;

export const EMPLOYEE_MATCH_COMPETENCES = gql`
  query($competences: [CompetenceInput]!) {
    employeeMatch(competences: $competences) {
      firstName
      lastName
      photo
      matchingSkills
      score
      _id
    }
  }
`;

export const MATCH_FIELDS = gql`
  fragment MatchFields on EmployeeMatch {
    firstName
    lastName
    photo
    matchingSkills
    score
    _id
  }
`;

export const EMPLOYEES_WITH_IMPORTANT_SKILLS = gql`
  query {
    employeesWithImportantSkills {
      name
      count
      skills {
        _id
        name
      }
      _id
    }
  }
`;

export const EMPLOYEE_BY_SKILL_TYPE = gql`
  query {
    employeeBySkillType {
      skillType
      employees {
        _id
        name
      }
    }
  }
`;

export const EMPLOYEE_REDUNDANT_COMPETENCES = gql`
  query {
    employeeRedundantCompetences {
      _id
      employees {
        _id
        firstName
        lastName
      }
      count
      skill {
        color
        name
      }
    }
  }
`;
