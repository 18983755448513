import { useCallback } from "react";
import { useIntl } from "react-intl";
import {
  Link,
  RouteComponentProps,
  useHistory,
  useParams,
} from "react-router-dom";

import axios from "axios";

import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import {
  JOB_BY_ID,
  JOB_MANY,
  JOB_MANY_GROUP_BY_UNIT,
  JOB_REMOVE_BY_ID,
  JOB_UPDATE_BY_ID,
} from "../../schema/job";
import { UNIT_MANY } from "../../schema/unit";

import SliderOverContext from "../../context/SliderOverContext";
import useToggle from "../../hooks/useToogle";

import { File } from "../../types";
import Dropzone from "../employees/Dropzone";
import FileList from "../files/List";
import { Error, Loading } from "../ui/Alerts";
import SlideOver from "../ui/SlideOver";
import { Divider } from "../ui/layout/Divider";
import Form, { FormData as FormDataType } from "./Form";

import { currencyFormater } from "../../config/i18n";
import ProfileChart from "../charts/ProfileChart";
import MetricTooltip from "../competences/MetricTooltip";
import RevenueFields from "../revenues/Fields";
import Stats from "./Stats";

interface ParamTypes {
  id: string;
}

const Details = ({}: RouteComponentProps) => {
  const { id } = useParams<ParamTypes>();
  const history = useHistory();
  const [open, toggle] = useToggle();
  const { formatMessage: f } = useIntl();

  const client = useApolloClient();
  const { loading, error, data } = useQuery(JOB_BY_ID, {
    variables: {
      _id: id,
    },
  });
  const [updateJob] = useMutation(JOB_UPDATE_BY_ID, {
    refetchQueries: [
      { query: JOB_MANY },
      { query: JOB_MANY_GROUP_BY_UNIT },
      {
        query: JOB_BY_ID,
        variables: {
          _id: id,
        },
      },
      { query: UNIT_MANY },
    ],
  });
  const [deleteJob] = useMutation(JOB_REMOVE_BY_ID, {
    refetchQueries: [{ query: JOB_MANY }, { query: JOB_MANY_GROUP_BY_UNIT }],
  });

  const onDrop = useCallback(
    async (acceptedFiles) => {
      const formData = new FormData();
      acceptedFiles.forEach((file: any) => {
        formData.append("file", file);
      });

      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${process.env.REACT_APP_FILES_URI}/upload`,
        formData,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      // Merge with actual job files
      const fileIds = [...data?.jobById.fileIds, ...response.data];
      updateJob({
        variables: {
          _id: id,
          fileIds: fileIds,
        },
      });
    },
    [id, updateJob, data]
  );

  const onUpdate = (formData: FormDataType) => {
    updateJob({
      variables: {
        _id: id,
        name: formData.name,
        description: formData.description,
        jobDescriptionId: formData.jobDescriptionId
          ? formData.jobDescriptionId
          : null,
        salary: +formData.salary,
        costs: +formData.costs,
        unitId: formData.unitId ? formData.unitId : null,
      },
    });
    history.goBack();
  };

  const onUpdateRevenues = (formData: FormDataType) => {
    updateJob({
      variables: {
        _id: id,
        revenues: formData.revenues.map((r) => ({
          ...r,
          year: +r.year,
          target: +r.target,
          actual: +r.actual,
        })),
      },
    });
    history.goBack();
  };

  const onDelete = () => {
    deleteJob({ variables: { _id: id } });
    history.goBack();
  };

  const onDeleteFile = (file: File) => {
    const fileIds = data?.jobById.fileIds.filter(
      (id: string) => id !== file._id
    );
    updateJob({
      variables: {
        _id: id,
        fileIds: fileIds,
      },
    });

    client.cache.modify({
      id: client.cache.identify(data?.jobById),
      fields: {
        fileIds(existing = []) {
          return existing.filter((fileId: string) => fileId !== file._id);
        },
      },
    });
  };

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;

  const { unitId, unit, salary, competences } = data.jobById;

  const formattedSalary = currencyFormater.format(salary);

  return (
    <>
      <SliderOverContext.Provider value={{ open: open, toggle }}>
        <SlideOver title="Arbeitsplatz bearbeiten">
          <Form onSubmit={onUpdate} data={data.jobById} />
        </SlideOver>
      </SliderOverContext.Provider>

      <div className="bg-gray-100 mb-4">
        <div className="bg-white shadow">
          <div className="container mx-auto px-6">
            <div className="flex items-center justify-between py-5">
              <div>
                <Link
                  to="/jobs"
                  className="inline-flex items-center text-sm text-gray-500 leading-none pb-2"
                >
                  <i className="fas fa-angle-left"></i>
                  <span>{f({ id: "job.name" })}</span>
                </Link>
                <div className="flex items-center">
                  <h1 className="px-2 text-2xl text-gray-900 font-extrabold">
                    {data.jobById.name}
                  </h1>
                </div>
              </div>
              <div className="flex items-center">
                <span className="shadow-sm rounded-md">
                  <button
                    type="button"
                    className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:text-gray-800 active:bg-gray-50 transition duration-150 ease-in-out"
                    onClick={toggle}
                  >
                    {f({ id: "actions.edit" })}
                  </button>
                </span>
                <span className="ml-3 shadow-sm rounded-md">
                  <button
                    type="button"
                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:shadow-outline-red focus:border-red-700 active:bg-red-700 transition duration-150 ease-in-out"
                    onClick={onDelete}
                  >
                    {f({ id: "actions.delete" })}
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="container mx-auto p-6">
          <div className="w-full px-2 pb-6">
            <h2 className="text-lg font-medium text-gray-900 pb-6">
              {f({ id: "skills.name" })}
            </h2>
            <div className="bg-white rounded shadow text-gray-600">
              <Stats
                humatics={data.jobById?.humatics}
                actualRevenue={data.jobById?.actualRevenue}
                targetRevenue={data.jobById?.targetRevenue}
              />
            </div>
          </div>
          <div className="flex -mx-2">
            <div className="w-1/2 px-2">
              <div className="mb-6">
                <h2 className="text-lg font-medium text-gray-900 pb-6">
                  {f({ id: "summary.name" })}
                </h2>
                <div className="bg-white rounded shadow overflow-hidden">
                  <div className="px-6 py-5">
                    <dl>
                      <div className="flex">
                        <div className="w-3/5 mb-6">
                          <dt className="text-sm font-medium text-gray-500">
                            {f({ id: "unit.name" })}
                          </dt>
                          <dd className="text-base font-normal text-gray-900">
                            <Link to={`/units/${unitId}/details`}>
                              {unit?.name}
                            </Link>
                          </dd>
                        </div>
                        <div className="w-2/5 mb-6">
                          <dt className="text-sm font-medium text-gray-500">
                            {f({ id: "salary.job" })}
                          </dt>
                          <dd className="text-base font-normal text-gray-900">
                            {formattedSalary}
                          </dd>
                        </div>
                      </div>
                      <div className="mt-8 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="text-sm leading-5 font-medium text-gray-500">
                          {f({ id: "meta.description" })}
                        </dt>
                        <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                          {data?.jobById?.description}
                        </dd>
                      </div>
                      <div className="mt-8 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="text-sm leading-5 font-medium text-gray-500">
                          {f({ id: "employees.target" })}
                        </dt>
                        <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                          {data?.jobById?.required?.employeeCount}
                        </dd>
                      </div>
                      <div className="mt-8 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="text-sm leading-5 font-medium text-gray-500">
                          {f({ id: "employees.actual" })}
                        </dt>
                        <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                          {data?.jobById?.employeeCount}
                        </dd>
                      </div>
                      <div className="mt-8 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="text-sm leading-5 font-medium text-gray-500">
                          {f({ id: "salaries.target" })}
                        </dt>
                        <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                          {currencyFormater.format(
                            data?.jobById?.required?.totalSalary
                          )}
                        </dd>
                      </div>
                      <div className="mt-8 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="text-sm leading-5 font-medium text-gray-500">
                          {f({ id: "salaries.actual" })}
                        </dt>
                        <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                          {currencyFormater.format(data?.jobById?.totalSalary)}
                        </dd>
                      </div>
                      <div className="flex mt-12">
                        <div className="w-full">
                          <dt className="text-sm font-medium text-gray-500">
                            {data?.jobById.fileIds.length}{" "}
                            {f({ id: "files.name" })}
                          </dt>
                          <dd className="mt-2">
                            <Dropzone onDrop={onDrop} />
                            <FileList
                              data={data?.jobById.files}
                              onDelete={onDeleteFile}
                            />
                          </dd>
                        </div>
                      </div>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-1/2 px-2">
              {/* Revenues */}
              <div className="mb-6">
                <h2 className="text-lg font-medium text-gray-900 pb-6">
                  {f({ id: "revenues.name" })}
                </h2>
                <div className="bg-white rounded shadow overflow-hidden text-gray-600">
                  <RevenueFields
                    onSubmit={onUpdateRevenues}
                    data={data?.jobById?.revenues}
                  />
                </div>
              </div>

              {/* Profile */}
              <h2 className="text-lg font-medium text-gray-900 pb-6">
                {f({ id: "skills.name" })}
              </h2>
              <div className="bg-white rounded shadow text-gray-600">
                <div>
                  <div className="flex"></div>
                  <div className="flex overflow-hidden">
                    <div className="w-full">
                      <ProfileChart
                        width={400}
                        height={200}
                        readonly={true}
                        data={competences}
                      />
                    </div>
                  </div>
                  <div className="flex bg-white px-6 py-4 border-t">
                    <Link
                      to={`/jobs/${id}/profile`}
                      className="w-full text-center px-6 py-2 bg-coral-500 rounded"
                    >
                      <span className="text-white">
                        {f({ id: "profile.name" })}
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Divider>
        <Divider.Content>{f({ id: "revenues.name" })}</Divider.Content>
      </Divider>

      <table className="my-6 min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-100">
          <tr>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              <MetricTooltip
                text={f({ id: "revenues.year.target.description" })}
              >
                <>{f({ id: "revenues.year.target" })}</>
              </MetricTooltip>
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              <MetricTooltip
                text={f({ id: "revenues.year.actual.description" })}
              >
                <>{f({ id: "revenues.year.actual" })}</>
              </MetricTooltip>
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              <MetricTooltip
                text={f({ id: "revenues.year.annualized.description" })}
              >
                <>{f({ id: "revenues.year.annualized" })}</>
              </MetricTooltip>
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          <tr>
            <td className="px-6 py-4">
              {currencyFormater.format(data?.jobById.targetRevenue)}
            </td>
            <td className="px-6 py-4">
              {currencyFormater.format(data?.jobById.actualRevenue)}
            </td>
            <td className="px-6 py-4">
              {currencyFormater.format(data?.jobById.annualizedRevenue)}
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default Details;
